import React from "react"
import Layout from "../components/Layout/Layout"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import { Link } from "gatsby"
import Divider from "../components/Divider/Divider"

const BlogIndexTemplate = ({ data: { allMdx }, pageContext}) => {
  
  const posts = allMdx.edges;

  let nextPage = "";
  let prevPage = "";

  if(pageContext.currentPage === 2) {
    prevPage = "/writing";
  }
  else if(pageContext.currentPage >= 3) {
    prevPage = "/writing/" + (pageContext.currentPage - 1);
  }

  if(pageContext.currentPage < pageContext.numPages ) {
    nextPage = "/writing/" + (pageContext.currentPage + 1);
  }

  return (
    <Layout>
      <SEO title="Thought Index" description="A collection of miniature blog posts extracted from brain waves."/>
      <div className="mt-16 mb-12">
        <h1 className="">Thoughts</h1>
        <p className="mb-6">Strange writings for strange times. </p>
        <div className="bg-blue-100 rounded-md text-blue-800 py-4 px-6"><p className="mb-0">Views do not represent those of my employer, friends, or family. Like all human beings, my thoughts are subject to change depending on how I feel, information I learn, and the amount of caffeine I've ingested.</p></div>
        
      </div>

      <div>
      {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.frontmatter.slug
          return (
            <div className="mb-16" key={title}>
              <h3>{title}</h3>
              <p className="mb-2">{node.frontmatter.excerpt}</p>
              <p className="italic mb-4">{node.frontmatter.date}</p>
              <Link to={node.frontmatter.slug} key={node.frontmatter.slug}>Read Post</Link>
            </div>
            )
        })}

      </div>  
      
<Divider />

      {prevPage && (<div><Link to={prevPage}>Previous Page</Link></div>)}
      {nextPage && (<div><Link to={nextPage}>Next Page</Link></div>)}

    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogIndexQuery($skip: Int!, $limit: Int!) {
    allMdx(
      filter: {frontmatter: {posttype: {eq: "blog"}}},
      sort: {order: DESC, fields: frontmatter___date},
      limit: $limit,
      skip: $skip
      ) {
      edges {
        node {
          frontmatter {
            slug
            title
            excerpt
            date(formatString: "MMMM Do, YYYY")
          }
        }
      }
  }
}
`

export default BlogIndexTemplate
